.CompleteRegistration_skip {
  font-family: "openSans";
  color: #00c389 !important;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;
}
.CompleteRegistration_conplite_porcentege {
  font-family: "openSans";
  font-size: 20px;
  color: #f6ac32;
  margin-bottom: 0px !important;
}

.ContactPageComponent_header {
  background-color: #fff3e0;
  border-radius: 8px;
}

.progress {
  width: 100px;
  height: 100px;
  line-height: 50px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #ccc6c1;
  position: absolute;
  top: 0;
  left: 0;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  font-family: "openSans-Semibold";
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: transparent;
  font-size: 29px;
  color: #f6ac32;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: -17%;
  left: 5%;
}

.progress.blue .progress-bar {
  border-color: #f6ac32;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}

.progress-left .progress-bar {
  animation: loading-4 0.4s linear forwards 1.8s;
}

.progress-left .progress-bar {
  animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .CompleteRegistration_conplite_porcentege {
    font-family: "openSans";
    font-size: 17px;
    color: #f6ac32;
    margin-bottom: 0px !important;
  }
  .progress .progress-value {
    font-size: 27px;
  }
}
@media screen and (min-width: 700px) and (max-width: 1000px) {
  .CompleteRegistration_conplite_porcentege {
    font-family: "openSans";
    font-size: 14px;
    color: #f6ac32;
    margin-bottom: 0px !important;
  }
  .progress .progress-value {
    font-size: 24px;
  }
}
