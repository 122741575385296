.sign_up_notification {
  display: flex;
  justify-content: center;
  font-family: "openSans-Light";
  background-color: #00e2a6;
  color: #fff;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.sign_up_notification i {
  margin-right: 10px;
  font-size: 25px;
}

.sign_up_notification p a {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.sign_up_notification p a:hover {
  color: blue;
  text-decoration: underline;
}
