.ShareCardBody strong {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
}
.ShareCardHeader {
  background-color: #00c389 !important;
  font-family: "openSans-Bold";
  font-size: 14px;
  color: white;
  width: 100.1% !important;
}
.ShareCard {
  border-radius: 5px !important;
}
.ShareCardBody {
  padding: 0.55rem !important;
}
.ShareCardBody p {
  font-family: "openSans-Light";
  font-size: 15px;
  color: #91a8ae;
}
