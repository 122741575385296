.PrivacyPolicy {
  padding-top: 25px;
  padding-bottom: 120px;
}
.PrivacyPolicy_conatianer {
  padding-bottom: 30px;
}
.PrivacyPolicy_pharagraphes {
  font-family: "Roboto";
  font-size: 16px;
  color: #284358;
  margin-bottom: 0px;
}
.PrivacyPolicy_big_title {
  font-family: "Roboto_Bold";
  font-size: 19px;
  color: #284358;
}
.PrivacyPolicy_table {
  border: 1px solid #bebebe;
  width: 100%;
}
.PrivacyPolicy_table thead {
  background-color: #f7f7f7;
}
.PrivacyPolicy_table thead th {
  font-family: "Roboto";
  font-size: 16px;
  color: #284358;
  font-weight: normal;
}
.PrivacyPolicy_table thead th,
.PrivacyPolicy_table td,
.PrivacyPolicy_table th {
  border-bottom: 1px solid #bebebe;
  border-left: 1px solid #bebebe;
}
.PrivacyPolicy_pharagraphes a {
  color: #2680eb;
  text-decoration: underline;
}
.PrivacyPolicy_sub_conatianer {
  padding-left: 20px;
  padding-bottom: 30px;
}

.PrivacyPolicy_title {
  font-family: "Roboto_Bold";
  font-size: 13px;
  color: #284358;
}

.PrivacyPolicy_pharagraphes_with_dot {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}
