.ContactFormHeader {
  text-align: center;
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 2%;
  padding-left: 32%;
  padding-right: 32%;
}
.contactFormTitleAndParagraphe {
  width: 50%;
}
.ContactFormTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.ContactFormparagraphe {
  font-family: "openSans";
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
}
.ContactFormCorp {
  padding: 3%;
  /* width: 50% !important; */
}
.ContactInputAndLabel {
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.ContactFormLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
}
.ContactFormInputs {
  width: 100% !important;
  height: 42px !important;
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  color: #52747d;
  border-radius: 3px !important;
  border: 2px solid #e5eef7 !important;
}
.ContactFormTextErea {
  width: 100% !important;
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  color: #52747d;
  border-radius: 3px !important;
  border: 2px solid #e5eef7 !important;
}
.ContactFormButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  height: 42px;
  width: 126px;
  border-radius: 3px !important;
  padding: 12px 14px 12px 14px !important;
}
.ContactFormButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 12px 14px 12px 14px !important;
}
.form_group_paraphe {
  font-family: "openSans";
  font-size: 12px;
  color: #535e60;
}
.form_group_paraphe a {
  color: #07c389;
}

.form_group_paraphe_error{
  font-family: "openSans";
  font-size: 12px;
  color: red;
}
.form_group_paraphe strong {
  font-family: "openSans-Bold";
}

.form_group_paraphe_new {
  font-family: "openSans";
  font-size: 12px;
  color: #535e60;
  margin-bottom: 0px;
  margin-top: 3px;
}

.form_group_paraphe_new a {
  color: #07c389;
  text-decoration: underline;
}
.form_group_paraphe_new a:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #07c389;
}

.form_group_paraphe a:hover {
  color: #07c389;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00c389 !important;
  border-color: #00c389 !important;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  width: 20px;
  height: 20px;
  border: 0px;
  box-shadow: none;
}
.custom-control-label:before {
  width: 20px;
  height: 20px;
  background-color: #dedede !important;
  border: none;
  box-shadow: none;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00c38915, 0 0 0 0.2rem #00c3897e;
  border-color: #00c389;
  box-shadow: none;
}
