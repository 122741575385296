.HorairesModelInputs input {
  width: 100% !important;
  text-align: center;
}
.HorairesModelInputs p {
  font-family: "openSans-Light";
  font-size: 12px;
  color: #494949;
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  margin-top: 4px;
}
