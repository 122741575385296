@keyframes removeAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#box {
  -webkit-animation: removeAnimation 1s infinite; /* Safari 4+ */
  -moz-animation: removeAnimation 1s infinite; /* Fx 5+ */
  -o-animation: removeAnimation 1s infinite; /* Opera 12+ */
  animation: removeAnimation 1s infinite; /* IE 10+, Fx 29+ */
}
.PharmacyFavoritComponent {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  height: auto;
  padding: 15px;
}
.PharmacyFavoritThead tr {
  background-color: #f3f7fb;
  padding: 15px 30px;
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
}
.PharmacyFavoritTBody tr td {
  font-family: "openSans-Light";
  /* padding: 8px 10px 8px 30px; */
  color: #91a8ae;
  font-size: 13px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
}
.deleteFavorites {
  display: table;
  margin-bottom: 5px;
  font-family: "openSans-Light";
  font-size: 12px;
  color: #f65f6e !important;
  text-decoration: none;
  cursor: pointer;
}
.deleteFavorites i {
  color: #f65f6e !important;
}
.viewFavorites {
  display: table;
  margin-bottom: 5px;
  font-family: "openSans-Light";
  font-size: 12px;
  text-decoration: none;
  color: #00c389 !important;
  cursor: pointer;
}
.viewFavorites i {
  color: #00c389 !important;
}
.AddFavorites {
  color: #f6ac32 !important;
  display: table;
  margin-bottom: 5px;
  font-family: "openSans-Light";
  font-size: 12px;
  text-decoration: none;
}
.AddFavorites i {
  color: #f6ac32 !important;
}
.PharmacyFavoritTable {
  width: 95%;
  margin: auto;
}
.PharmacyFavoritPagination {
  float: right;
  margin-top: 23px !important;
}
