.pharmacieInfoHeader {
  text-align: center;
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  height: 248px;
}
.pharmacieInfoprofil {
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  margin-top: -221px;
  margin-bottom: 30px;
  padding: 0px !important;
  overflow: hidden;
}
.profileInformations {
  margin-top: -65px;
  margin-left: 28px;
  height: 121px;
  position: relative;
}
.PhotoDeProfil {
  border-radius: 3px;
  border: 4px solid #fff;
  background-color: #f3f7fb;
  cursor: pointer;
  max-width: 120px;
  max-height: 121px;
  overflow: hidden;
}
.suggest_pharmacist_picture {
  width: 100%;
  height: 100%;
}
.ProfileInfo {
  margin-top: 28px;
  margin-left: 2%;
}
.navigationTab {
  margin-top: 30px;
  padding-left: 25px;
}
.navigationTab ul {
  list-style: none;
}
.newSuggestion {
  margin-left: 16%;
}
.newSuggestion:hover {
  color: #00c389 !important;
}
.navigationTab ul li {
  text-decoration: none;
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.pharmacyName {
  font-family: "vag-Bold";
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  margin-right: 10px;
}
.ProfileInfo p {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 5px !important;
}
.lien_add {
  font-family: "openSans";
  font-size: 13px;
  color: #00c389;
  text-decoration: none;
}
.lien_add:hover {
  font-family: "openSans";
  font-size: 13px;
  color: #00c389;
  text-decoration: underline;
}
.cover {
  position: relative;
  height: 221px;
  overflow: hidden;
}
.cover:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  height: 53%;
  background-image: repeating-linear-gradient(
    to top,
    rgb(0, 0, 0) -232.6%,
    rgba(0, 0, 0, 0) 100.9%
  );
}
.CoverImage {
  width: 100%;
  cursor: pointer;
}
.iSactive {
  border-bottom: 2px solid #00c389;
  color: #00c389 !important;
}
.row_about i {
  padding: 0px !important;
}
.RowInformtaions i {
  padding: 0px !important;
}
.AddTofavoris {
  color: #f6ac32;
  border-radius: 50%;
  padding: 4px;
  margin-right: -7%;
  z-index: 100;
  font-size: 16px !important;
}

.AddTofavoris_component {
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
}
.AddTofavoris_component_two {
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.AddTofavoris_component_two p {
  background-color: #fff;
  margin: 0px !important;
  color: #f6ac32;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1px 10px 1px 12px;
  border-radius: 10px 20px 20px 10px;
}

.AddedTofavoris_component_two {
  cursor: pointer;
  /* background-color: #f6ac32; */
  display: flex;
  align-items: center;
  /* border-radius: 20px; */
}

.AddedTofavoris_component_two p {
  margin: 0px !important;
  color: #fff;
  background-color: #f6ac32;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1px 10px 1px 12px;
  border-radius: 10px 20px 20px 10px;
}

.AddedTofavoris {
  color: #f6ac32;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  margin-right: -7%;
  z-index: 100;
  font-size: 12px !important;
}

input[type="radio"]:checked {
  background-color: red;
}

.about_horaire_bold td {
  font-family: "openSans-Bold" !important;
}

.custom-radio .custom-control-label::before {
  background-color: transparent !important;
  border: 1px solid #929c99 !important;
}

/* This is the checked state */
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #00c389 !important;
  border-radius: 50% !important;
  border-color: #00c389 !important;
  box-shadow: none !important;
}

/* active state i.e. displayed while the mouse is being pressed down */
.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: #00c389;
  background-color: #00c389; /* red */
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  display: none;
}
.RecomondationRatingAnswer label {
  font-family: "openSans-Bold";
  font-size: 13px;
  color: #697b80;
  padding-top: 4px !important;
}

.rating_error {
  background-color: #f2dedf;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid #bb4845;
}

.rating_error p {
  font-size: 12px;
  font-family: "openSans";
  color: #ba4a46;
  text-align: unset;
  margin-bottom: 0px;
}

.RecommendationYesBtn {
  width: max-content;
  background: #01ab78 !important;
  font-family: "openSans-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
}
.RecommendationNoBtn {
  width: max-content;
  font-family: "openSans-Bold" !important;
  font-size: 14px !important;
  background: #f65f6e !important;
  border: none !important;
  color: #fff !important;
}
.Recommendation {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}
.Recommendation h2 {
  text-align: center;
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
}
.SideBarBtn {
  width: 100%;
  border-radius: 5px !important;
  background-color: #f6ac32 !important;
  border: none !important;
  box-shadow: none !important;
}
.SideBarBtn:hover {
  width: 100%;
  border-radius: 5px !important;
  background-color: #eda225 !important;
  border: none !important;
  box-shadow: none !important;
}
.SideBarBtn p {
  font-family: "vag-Bold";
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  text-align: left;
  margin: 0px;
}
.ButtonImage {
  width: 48px;
  position: absolute;
  right: 4px;
}
.MapComponent {
  position: relative;
}
.leaflet-interactive {
  display: none;
}
.map_marker_container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  color: #00c389;
  font-size: 12px;
  text-align: center;
}
.map_marker_container i {
  font-size: 20px;
  color: #f65f6e;
  margin-right: 3px;
}
