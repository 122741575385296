.InfoPersonnelComponent {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  height: auto;
  padding: 15px;
  position: relative;
}
.InfoPersonnel div {
  background-color: #f3f7fb;
  border-radius: 3px;
  height: 44px;
  margin-right: 5px;
  margin-bottom: 10px;
  padding-top: 12px;
  padding-left: 10px;
  font-family: "openSans";
  color: #91a8ae;
  font-size: 13px;
  margin-left: 8px;
  overflow: hidden;
  display: flex;
  align-items: baseline;
}
.InfoPersonnel strong {
  font-family: "openSans-Bold";
  color: #91a8ae;
  font-size: 13px;
  width: auto;
}

.InfoPersonnelComponent_title {
  font-family: "openSans-Bold";
  color: #91a8ae;
  font-size: 16px;
  margin-top: -28px;
  background-color: #fff;
  width: max-content;
}

.InfoPersonnel p {
  width: auto;
}
.InfoPersonnelButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 12px 18px 12px 18px !important;
}
.InfoPersonnelButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 12px 18px 12px 18px !important;
}
