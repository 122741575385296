.NumberModal_paragraphe {
  font-family: "openSans" !important;
  color: #00c389 !important;
  font-size: 16px !important;
  /* align-items: center; */
}
.NumberModal {
  border: 1px solid #e7e7e7;
  color: #00c389 !important;
  width: max-content;
  margin: auto;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
}
