.ErreurComponentHeader {
  text-align: center;
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 2%;
}
.ErreurComponentTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.ErreurComponent_btn:hover {
  background-color: #eda225;
  color: white;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  box-shadow: none !important;
}
.ErreurComponent_btn {
  background-color: #f6ac32;
  color: white;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  box-shadow: none !important;
}
.ErreurComponentImage {
  width: 80%;
  height: auto;
}
