.PrescriptionPharmacieComponent {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  height: auto;
}

.PrescriptionPharmacieComponentTBody tr td {
  font-family: "openSans";
  padding: 8px ​30px 8px 18px;
  color: #91a8ae;
  font-size: 13px;
  border-top: 1px solid #e4e4e4;
}

.collapsButton {
  background-color: #e5f6f4;
  border-radius: 3px;
}
.collapse_is_open {
  background-color: #f3f6fb;
  width: 106%;
  margin-left: -3%;
}

.livButton {
  background-color: #91a8ae;
  border: 1px solid #91a8ae;
  border-radius: 3px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
  font-size: 10px;
}
.nouButton {
  background-color: #f2a628;
  border: 1px solid #f2a628;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}
.encButton {
  background-color: #3d7ea6;
  border: 1px solid #3d7ea6;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}

.preButton {
  background-color: #02c288;
  border: 1px solid #02c288;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}

.annButton {
  background-color: #f52951;
  border: 1px solid #f52951;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}
.prscreption_cancel {
  font-family: "openSans-Light";
  font-size: 10px;
  color: #f52951;
  cursor: pointer;
}

.collapse_is_open_title {
  font-family: "openSans-Bold";
  font-size: 10px;
  color: #91a8ae;
  margin-bottom: 0px;
}
.collapse_is_open_paragraphe {
  font-family: "openSans-Light";
  font-size: 10px;
  color: #91a8ae;
}
.Ordonnance_image_conatianer {
  cursor: pointer;
  width: 18%;
}
.Ordonnance_image {
  width: 100%;
}
.react-images__view--isModal img {
  width: 35% !important;
}
