.AboutUs {
  width: 100%;
  background-color: #f5f9fd;
}
.AboutUsButton {
  background-color: #f6ac32 !important;
  border-color: #f6ac32 !important;
  color: #fff !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  text-decoration: none !important;
  box-shadow: none !important;
  padding: 13px 14px 12px 14px !important;
}
.AboutUsButton:hover {
  background-color: #eda225 !important;
  border-color: #eda225 !important;
  color: #fff !important;
  font-family: "gotham-Bold" !important;
}
.AboutUsTitle {
  font-family: "vag-Bold";
  margin-top: 20px;
  font-size: 20px;
  color: #3d7ea8;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.AboutUsParagraphe {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
  line-height: 19px;
}
