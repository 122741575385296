.Footer {
  width: 100%;
  background-color: #223444;
}
.Footer h4 {
  font-family: "gotham-Bold";
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  /* padding-left: 27px; */
  margin-bottom: 18px;
  margin-top: 40px;
}
.Brand {
  background-color: #172735;
  color: #d4e6f8;
  font-size: 13px;
}

.FooterdescriptionPargaraphe {
  font-size: 14px !important;
  font-family: "openSans" !important;
  color: #aab6ca;
  font-weight: normal !important;
  margin-top: 20px;
  width: 115%;
}
.ImageEmail {
  width: auto !important;
  height: 18px !important;
}
.footerContact {
  /* margin-top: 40px; */
}
.footerContact li {
  list-style-type: none;
  display: flex;
}
.footerSolutions li {
  /* float: left; */
  list-style-type: none;
}
.footerSolutions li a {
  font-family: "openSans";
  font-size: 14px;
  font-weight: normal;
  color: #aab6ca;
  text-decoration: none;
}
.footerSolutions li a:hover {
  font-family: "openSans";
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-decoration: none;
}
.footerContact li p {
  font-family: "openSans";
  font-size: 14px;
  font-weight: normal;
  color: #aab6ca;
  text-decoration: none;
}
.footerContact img {
  width: 28px;
  height: 25px;
  margin-right: 6px;
}
.sobrusLogoFooter {
  margin-right: 5px;
  font-family: "openSans" !important;
  color: #8291a9;
  font-size: 14px !important;
}

.FooterdescriptionImg {
  width: 270px;
}
.original_year,
.BrandParagrphe {
  font-family: "openSans" !important;
  color: #d4e6f8;
  font-size: 13px !important;
}
.Brand a {
  cursor: pointer;
}
