.EditPresonnalInformation {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  height: auto;
  padding: 15px;
  position: relative;
}
.EditPresonnalInformation_title {
  font-family: "openSans-Bold";
  color: #91a8ae;
  font-size: 16px;
  margin-top: -28px;
  background-color: #fff;
  width: max-content;
}
.EditPresonnalInformationLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 8px;
}
.EditPresonnalInformationInputs {
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  height: 100%;
  border-width: 2px !important;
  border-color: #e5eef7 !important;
}
.EditPresonnalInformationButton {
  background-color: #fff;
  border: 1px solid #b2eddb;
  color: #b2eddb;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  box-shadow: none !important;
  padding: 12px 30px 12px 30px !important;
}
.EditPresonnalInformationButton:hover {
  border: 1px solid #00c389;
  color: #00c389;
  box-sizing: border-box;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  box-shadow: none !important;

  padding: 12px 30px 12px 30px !important;
}
.EditPresonnalInformationBtn {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 12px 30px 12px 30px !important;
}
.EditPresonnalInformationBtn:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 12px 30px 12px 30px !important;
}
