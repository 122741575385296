.EditMonCompteHeader {
  text-align: center;
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 2%;
  padding-left: 36%;
  padding-right: 36%;
}
.EditMonCompteTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.EditMonCompteparagraphe {
  font-family: "openSans";
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}
.EditMonCompteCorp {
  padding: 2%;
}
.EditMonCompteeDetailes p {
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  margin-bottom: 10px;
  padding-top: 18px;
  padding-bottom: 16px;
  text-align: left;
  color: #91a8ae;
  font-family: "gotham-Bold";
  font-size: 14px !important;
  padding-left: 10px;
}
.uiStateIsActive {
  background-color: #00c389 !important;
  color: #fff !important;
  border-color: #00c389 !important;
}
.IsActive {
  cursor: pointer;
}
