.blocPubImage {
  width: 226px;
  height: auto;
}
.CardButtonEmpty {
  background-color: #f4ab41 !important;
  border-color: #f4ab41 !important;
  color: #fff !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: "openSans-Bold" !important;
  /* padding: 5%; */
}
.CardButtonEmpty:hover {
  background-color: #f4ab41 !important;
  border-color: #f4ab41 !important;
  color: #fff !important;
  /* padding: 5%; */
}
.empty_bloc_pharmacie_title {
  font-family: "openSans-Bold" !important;
  font-size: 15px !important;
  color: #91a8ae !important;
  text-decoration: none !important;
  text-align: center !important;
}
.emptyPhotoAndtitleOfCard {
  font-family: "openSans-Semibold" !important;
  font-size: 15px !important;
  color: #91a8ae !important;
  text-align: center;
  padding: 6.3%;
}
.Previous{
  cursor: pointer;
}