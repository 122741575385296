.PharmacyConsulterComponent {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  height: auto;
  padding: 15px;
}
.PharmacyConsulterThead tr {
  background-color: #f3f7fb;
  padding: 15px 0px;
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
}
.PharmacyConsulterTBody tr td {
  font-family: "openSans";
  /* padding: 8px 15px 8px 30px; */
  color: #91a8ae;
  font-size: 13px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
}
.deleteFavorites {
  display: table;
  margin-bottom: 5px;
  font-family: "openSans";
  font-size: 12px;
  color: #f65f6e !important;
  text-decoration: none;
}
.deleteFavorites i {
  color: #f65f6e !important;
}
.viewFavorites {
  display: table;
  margin-bottom: 5px;
  font-family: "openSans";
  font-size: 12px;
  text-decoration: none;
  color: #00c389 !important;
}
.viewFavorites i {
  color: #00c389 !important;
}
.PharmacyConsulterTable {
  width: 95%;
  margin: auto;
}
.PharmacyConsulterPagination {
  float: right;
  margin-top: 23px !important;
}
.AddFavorites {
  cursor: pointer;
}
