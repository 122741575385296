.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  width: 20px;
  height: 20px;
  border: 0px;
  box-shadow: none;
}
.custom-control-label:before {
  width: 20px;
  height: 20px;
  background-color: #dedede;
  border: none;
  box-shadow: none;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00c38915, 0 0 0 0.2rem #00c3897e;
  border-color: #00c389;
  box-shadow: none;
}
.CheckBoxLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 8px;
}
