.SearchErrourComponent {
  text-align: center;
}
.noResultTitle {
  font-family: "vag-Bold";
  font-size: 25px;
  color: #3d7ea8;
  text-transform: uppercase;
  margin-top: 20px;
}
.noResultParagraphe {
  font-family: "openSans-Light";
  font-size: 14px;
  color: #91a8ae;
  margin-top: 20px;
}
.noResultButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 5% 4% !important;
  margin-top: 20px;
  width: 100%;
}
.noResultButton:hover {
  padding: 5% 4% !important;
  background-color: #027a56 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin-top: 20px;
  width: 100%;
}
.noResultImage {
  width: 40%;
}
.search_result_ou {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  padding-top: 5%;
}
.search_result_ou_span_right {
  content: "";
  height: 1px;
  width: 40%;
  right: 0px;
  background-color: #e7e7e7;
  margin-bottom: 5%;
}
.search_result_ou_span_left {
  content: "";
  height: 1px;
  width: 40%;
  left: 0px;
  background-color: #e7e7e7;
  margin-bottom: 5%;
}
.search_result_ou_span_text {
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  color: #91a8ae;
}
.SuggérerResultButton {
  background-color: #f4ab41 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 5% 4% !important;
  width: 100%;
  margin-top: 20px;
}
.SuggérerResultButton:hover {
  padding: 5% 4% !important;
  background-color: #b88232 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin-top: 20px;
  width: 100%;
}
.SearchErrourComponent_buttons_group {
  width: 80%;
  margin: auto;
}
.SearchRComponent {
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 2%;
}
.bloc_search {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  vertical-align: middle;
  -webkit-box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
}
.SearchInputs {
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  height: 100%;
  border-width: 2px !important;
  border-color: #e5eef7 !important;
}
.submitSearch {
  border: 2px solid #00c389 !important;
  background-color: #00c389 !important;
  color: #fff;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  height: 42px !important;
  width: 100%;
  padding: 0px !important;
}
.submitSearch:hover {
  background-color: #01ae7b !important;
  border: 2px solid #01ae7b !important;
  color: #fff !important;
}
