.BlocInformationsRempli {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}
.row_about_title {
  font-family: "openSans-Bold" !important;
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
  margin-top: 12px;
  margin-right: 5px;
}
.row_about p {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
  margin-top: 12px;
  margin-right: 5px;
  margin-bottom: 0px !important;
}
.row_about p span {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
  margin-top: 12px;
  margin-right: 5px;
}
.row_about {
  display: flex;
  align-items: baseline;
}
.row_about i {
  color: #00c389 !important;
}
.row_about a {
  color: #00c389 !important;
  cursor: pointer;
}
.row_about span {
  font-family: "openSans-Light";
  font-size: 15px;
}
.RowInformtaions i {
  color: #00c389 !important;
}
.RowInformtaions a {
  color: #00c389 !important;
}
.RowInformtaions span {
  font-family: "openSans-Light";
  font-size: 15px;
}
.about_horaire table {
  width: 99%;
}
.about_horaire tr {
  height: 35px;
  background-color: #f3f7fb;
  border-radius: 3px;
  width: 94%;
  margin-left: 25px;
  margin-top: 6px;
  padding: 8px;
  display: flex;
  align-items: baseline;
}
.about_horaire td {
  font-family: "openSans";
  font-size: 13px;
  color: #789198;
  border: none;
}
.day {
  width: 60%;
  margin-top: -7px;
}
.day-time {
  width: 20%;
  align-items: flex-end;
  text-align: center;
}
.modes_paiement {
  margin-top: 15px;
}
.modes_paiement li {
  width: 134px;
  height: 90px;
  border-radius: 3px;
  background-color: #f5f9fd;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  padding: 2%;
  overflow: hidden;
}
.modes_paiement li p {
  font-family: "openSans" !important;
  font-size: 13px;
  text-align: center;
  color: #789198;
  margin-top: 10px;
}
.service {
  padding-left: 25px;
}

.show_more {
  border-top: 1px solid #e7e7e7;
  width: 93%;
  border-bottom: 1px solid #e7e7e7;
  padding: 8px 0;
  margin-left: 25px;
  margin-top: 5px;
  text-align: center;
}
.lien_add {
  font-family: "openSans";
  font-size: 14px;
  color: #00c389 !important;
  cursor: pointer;
}
.lien_add:hover {
  font-family: "openSans";
  font-size: 14px;
  color: #00c389 !important;
  cursor: pointer;
  text-decoration: underline !important;
}
.services {
  width: 100%;
}
.blocInformationsVide {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}
.RowInformtaions p {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
}
.RowInformtaionsTitle {
  font-family: "openSans-Bold" !important;
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
}
.row_about p {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
  margin-top: 12px;
  margin-right: 5px;
  margin-bottom: 0px !important;
}
.row_about {
  display: flex;
  align-items: baseline;
}
.row_about i {
  color: #00c389 !important;
  padding-right: 2%;
}
.row_about a {
  color: #00c389 !important;
}
.row_about span {
  font-family: "openSans-Light";
  font-size: 15px;
}
.RowInformtaions i {
  color: #00c389 !important;
  padding-right: 2%;
}
.RowInformtaions a {
  color: #00c389 !important;
}
.RowInformtaions span {
  font-family: "openSans-Light";
  font-size: 15px;
}
.about_horaire table {
  width: 99%;
}
.about_horaire tr {
  height: 35px;
  background-color: #f3f7fb;
  border-radius: 3px;
  width: 94%;
  margin-left: 25px;
  margin-top: 6px;
  padding: 8px;
  display: flex;
  align-items: baseline;
}
.about_horaire td {
  font-family: "openSans";
  font-size: 13px;
  color: #789198;
  border: none;
}
.day {
  width: 60%;
  margin-top: -7px;
}
.day-time {
  width: 20%;
  align-items: flex-end;
  text-align: center;
}
.modes_paiement {
  margin-top: 15px;
}
.modes_paiement p {
  font-family: "openSans-Light";
  font-size: 13px;
  text-align: center;
  color: #789198;
  margin-top: 10px;
}
.service {
  padding-left: 25px;
}
.show_more {
  border-top: 1px solid #e7e7e7;
  width: 93%;
  border-bottom: 1px solid #e7e7e7;
  padding: 8px 0;
  margin-left: 25px;
  margin-top: 5px;
  text-align: center;
}
.services {
  width: 100%;
}
.localisationCardBody strong {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
}
.LocalisationCardHeader {
  background-color: #00c389 !important;
  font-family: "openSans-Bold";
  font-size: 14px;
  color: white;
  width: 100.1% !important;
}
.localisationCard {
  border-radius: 4px !important;
}
.localisationCardBody {
  padding: 0 !important;
}
.localisationCardBody p {
  padding: 0.55rem !important;
  font-family: "openSans";
  font-size: 15px;
  color: #91a8ae;
  margin-bottom: 5px !important;
}
.RecomondationRating {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}
.RecomondationRatingButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  height: 42px;
  width: 126px;
  border-radius: 3px !important;
  padding: 12px 14px 12px 14px !important;
}
.RecomondationRatingButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 12px 14px 12px 14px !important;
}
.RecomondationRatingFrom {
  width: 70%;
}
.RecomondationRatingAnswer p {
  font-family: "openSans-Bold";
  font-size: 13px;
  color: #697b80;
  margin-bottom: 0px !important;
}
.RecommendationRatingTextErea p {
  font-family: "openSans-Bold";
  font-size: 13px;
  color: #697b80;
  margin-bottom: 0px !important;
}
.RecomondationRatingTextErea .RecomondationRatingButton {
  text-align: end;
}
.RecomondationRatingTextErea {
  box-shadow: none !important;
}
.PhotoGalerieVideComponent {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  text-align: center;
}
.GalerieButton {
  background-color: rgba(255, 255, 255, 0);
  margin-right: 5px;
  border: 2px solid #00c389;
  color: #00c389 !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding: 13px 23px 13px 23px !important;
  box-shadow: none !important;
}
.GalerieButton:hover {
  background-color: #01ae7b;
  transition: all ease-in-out 200ms;
  border: 2px solid #01ae7b;
  box-sizing: border-box;
  color: #fff !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  box-shadow: none !important;
}
.PhotoGalerieVideComponent h2 {
  font-family: "openSans-Bold";
  font-size: 20px;
  color: #a0b2b7;
}
.PhotoGalerieVideComponent p {
  font-family: "openSans-Light";
  font-size: 13px;
  color: #91a8ae;
}
.SearchComponent {
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #1bc48a, #1bc4c3);
  background: linear-gradient(to right, #1bc48a, #1bc4c3);
  color: white;
  text-align: center;
  padding: 5%;
}
.bloc_search {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  vertical-align: middle;
  -webkit-box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.2);
  width: 805px;
  padding: 12px;
}
.SearchInputs {
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  height: 100%;
  border-width: 2px !important;
  border-color: #e5eef7 !important;
  height: 42px !important;
}
.SearchTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
}
.submitSearch {
  border: 2px solid #00c389 !important;
  background-color: #00c389 !important;
  color: #fff;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  height: 42px;
  width: 100%;
}
.submitSearch:hover {
  background-color: #01ae7b !important;
  border: 2px solid #01ae7b !important;
  color: #fff !important;
}
.page-link {
  margin-right: 5px;
  height: 28px;
  width: 28px;
  border-radius: 3px;
  background-color: #f3f7fb;
  color: #597982;
  font-size: 13px;
  font-weight: bold;
  padding: 7px 9px 6px 11px;
  font-family: "openSans";
  text-decoration: none;
  display: inline-block;
  transition: all ease-in-out 200ms;
  display: unset !important;
  cursor: pointer;
}
.pagination_icon {
  font-size: 11px;
  color: #a2b4bb;
}
.LoginFromHeader {
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 3%;
}
.LoginFormButton {
  width: 100% !important;
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 12px 12px 13px 12px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
}
.LoginFormButton:hover {
  width: 100% !important;
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 12px 12px 13px 12px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
}
.LoginFormInputs {
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  height: 100%;
  border-width: 2px !important;
  border-color: #e5eef7 !important;
}
.LoginFromCorp {
  padding: 3%;
}
.LoginFormLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 8px;
}
.LoginChechboxLabels {
  color: #818c96;
  font-size: 14px;
  font-weight: normal;
  font-family: "openSans-Light";
  padding-left: 10px;
  padding-top: 2px;
}
.LoginFromTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.LoginFromparagraphe {
  font-family: "openSans";
  color: #fff;
  font-size: 14px;
}
.border-left {
  width: 45%;
  border-top: 1px solid #e7e7e7;
  float: left;
  position: relative;
  top: 12px;
}
.border-right {
  width: 45%;
  border-top: 1px solid #e7e7e7;
  float: right;
  position: relative;
  top: 12px;
}
.OautoSpan {
  font-size: 13px;
  color: #91a8ae;
  font-family: "gotham-Bold";
}
.InscreptoonButtons {
  border-top: 1px solid #e7e7e7;
  text-align: center !important;
}
.OautoButtons {
  text-align: center !important;
}
.LoginFormMotDePasseOub {
  text-decoration: none;
  color: #91a8ae !important;
  font-size: 14px;
  font-family: "openSans";
  cursor: pointer;
}
.LoginFormMotDePasseOub:hover {
  text-decoration: none;
  color: #91a8ae;
  font-size: 14px;
  font-family: "openSans";
}

.custom-control-label:before {
  background-color: white;
  width: 20px;
  height: 20px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00c38915, 0 0 0 0.2rem #00c3897e;
  border-color: #00c389;
}
.LoginFormInscrivezButton {
  width: 100% !important;
  background-color: #f6ac32;

  transition: all ease-in-out 200ms;
  color: white;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  box-shadow: none !important;
  padding: 13px 23px 13px 23px !important;
  border: none !important;
}
.LoginFormInscrivezButton:hover {
  width: 100% !important;
  background-color: #eda225;
  transition: all ease-in-out 200ms;
  color: white;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding: 13px 23px 13px 23px !important;
  box-shadow: none !important;
  border: none !important;
}
.LoginFormButtonFacebook {
  width: 100% !important;
  background-color: #4267b2 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 12px 12px 13px 12px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
}

.LoginFormButtonGoogle {
  width: 100% !important;
  background-color: #f5f5f5 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 12px 12px 13px 12px !important;
  box-shadow: none !important;
  border: none !important;
  color: #b6b6b6 !important;
}

.facebook_icon {
  font-size: 25px;
  float: left;
}

.google_icon {
  float: left;
  height: 25px;
  width: auto;
}

.notification {
  display: flex;
  justify-content: center;
  font-family: "openSans-Light";
  background-color: #ff5252;
  color: #fff;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.notification i {
  margin-right: 60px;
  font-size: 25px;
}
.choice_body {
  text-align: center;
}
.SingupChoiceComponent {
  padding: 8%;
}
.SingupChoiceOuText {
  font-family: "gotham-Bold";
  font-size: 25px;
  color: #97adb2;
}
.SingupChoiceOuTopBordet {
  position: absolute;
  content: "";
  height: 20%;
  width: 1px;
  background-color: #e7e7e7;
  top: 18%;
  margin-left: 1.5%;
}
.SingupChoiceOuBottomBordet {
  position: absolute;
  content: "";
  height: 25%;
  width: 1px;
  background-color: #e7e7e7;
  bottom: 26%;
  margin-left: 1.5%;
}
.SingupChoiceOu {
}
.choice_button_patien {
  background-color: #f4ab41 !important;
  border-color: #f4ab41 !important;
  color: #fff !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: "openSans-Bold" !important;
  margin-top: 10%;
}
.choice_button_patien:hover {
  background-color: #f4ab41 !important;
  border-color: #f4ab41 !important;
  color: #fff !important;
  margin-top: 10%;
}
.choice_button_doc {
  background-color: #5cc58a !important;
  border-color: #5cc58a !important;
  color: #fff !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: "openSans-Bold" !important;
  margin-top: 13%;
}
.choice_button_doc:hover {
  background-color: #5cc58a !important;
  border-color: #5cc58a !important;
  color: #fff !important;
  margin-top: 13%;
}
.LoginFromHeader {
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 2%;
}
.RegisterFormButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  height: 42px;
  width: 126px;
  border-radius: 3px !important;
  padding: 12px 14px 12px 14px !important;
}
.RegisterFormButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 12px 14px 12px 14px !important;
}
.RegisterFormInputs {
  width: 100% !important;
  height: 42px !important;
  box-shadow: none !important;
  /* padding: 12px 12px 13px 12px !important; */
  color: #52747d;
  border-radius: 3px !important;
  border: 2px solid #e5eef7 !important;
}
.RegesterFormCorp {
  padding: 3%;
  /* width: 50% !important; */
}
.RegisterFormLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
}
.RegisterChechboxLabels {
  color: #818c96;
  font-size: 14px;
  font-weight: normal;
  font-family: "openSans-Light";
  padding-left: 10px;
  padding-top: 2px;
}
.RegisterFromTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.RegisterFromparagraphe {
  font-family: "openSans";
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px !important;
}
.custom-control-label:before {
  background-color: white;
  width: 20px;
  height: 20px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #00c389;
  border-color: #00c389;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00c38915, 0 0 0 0.2rem #00c3897e;
  border-color: #00c389;
}
.RegesterInputAndLabel {
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.erreurInputText {
  color: red;
}
.choice_body {
  text-align: center;
}
.SingupChoiceComponent {
  padding: 8%;
}
.SingupChoiceOuText {
  font-family: "gotham-Bold";
  font-size: 25px;
  color: #97adb2;
}
.SingupChoiceOuTopBordet {
  position: absolute;
  content: "";
  height: 20%;
  width: 1px;
  background-color: #e7e7e7;
  top: 18%;
  margin-left: 1.5%;
}
.SingupChoiceOuBottomBordet {
  position: absolute;
  content: "";
  height: 25%;
  width: 1px;
  background-color: #e7e7e7;
  bottom: 26%;
  margin-left: 1.5%;
}
.SingupChoiceOu {
}
.choice_button_patien {
  background-color: #f4ab41 !important;
  border-color: #f4ab41 !important;
  color: #fff !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: "openSans-Bold" !important;
  margin-top: 10%;
}
.choice_button_patien:hover {
  background-color: #f4ab41 !important;
  border-color: #f4ab41 !important;
  color: #fff !important;
  margin-top: 10%;
}
.choice_button_doc {
  background-color: #5cc58a !important;
  border-color: #5cc58a !important;
  color: #fff !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: "openSans-Bold" !important;
  margin-top: 13%;
}
.choice_button_doc:hover {
  background-color: #5cc58a !important;
  border-color: #5cc58a !important;
  color: #fff !important;
  margin-top: 13%;
}
.EditPassWord {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  height: auto;
  padding: 15px;
}
.EditPassWordLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 8px;
}
.EditPassWordInputs {
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  height: 100%;
  border-width: 2px !important;
  border-color: #e5eef7 !important;
}
.error_pass_word {
  color: red !important;
  background: none !important;
  padding-left: 0px !important;
}
.InfoPersonnelButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 12px 18px 12px 18px !important;
}
.InfoPersonnelButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 12px 18px 12px 18px !important;
}
.MotDePassOublieWithTokenButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 2% 7%;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  float: right;
}
.MotDePassOublieWithTokenButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 2% 7%;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  display: flex;
  justify-content: end;
}
.MotDePassOublieHeader {
  width: 100%;
  background-color: #00c389;
  background: -webkit-linear-gradient(
    left,
    #1bc48a,
    #1bc4c3
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #1bc48a,
    #1bc4c3
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #1bc48a, #1bc4c3); /* Standard syntax */
  color: white;
  text-align: center;
  padding: 3%;
  padding-left: 32%;
  padding-right: 32%;
}
.MotDePassOublieButton {
  width: 100% !important;
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 12px 12px 13px 12px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
}
.MotDePassOublieButton:hover {
  width: 100% !important;
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  padding: 12px 12px 13px 12px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
}
.MotDePassOublieTitle {
  font-family: "vag-Bold";
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.MotDePassOublieparagraphe {
  font-family: "openSans";
  color: #fff;
  font-size: 14px;
}
.MotDePassOublieLabels {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 8px;
}
.MotDePassOublieInputs {
  box-shadow: none !important;
  padding: 12px 12px 13px 12px !important;
  height: 100%;
  border-width: 2px !important;
  border-color: #e5eef7 !important;
}

.email_sent {
  text-align: center;
}

.email_sent_icon {
  font-size: 60px;
  color: #00c389;
  margin-bottom: 8%;
}

.email_sent_paragraphe {
  font-family: "openSans";
  font-size: 14px;
  color: #91a8ae;
}
