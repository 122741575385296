.PrprietaireModalBody p {
  color: #91a8ae;
  font-size: 14px;
  margin-top: 5px;
  font-family: "openSans-Light";
  text-align: start;
}
.PrprietaireModalBody p strong {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  text-align: left;
  margin-left: 10px;
}
