.LoginModalButton {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 0px !important;
  padding: 20px !important;
}
.LoginModalButton:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 20px !important;
}
.RegisterModalButton:hover {
  background-color: #eda225;
  color: white;
  border: none !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  border-radius: 0px !important;
  padding: 20px !important;
  box-shadow: none !important;
}
.RegisterModalButton {
  background-color: #f6ac32;
  color: white;
  border: none !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  border-radius: 0px !important;
  padding: 20px !important;
  box-shadow: none !important;
}

.LoginModalmodal {
  width: 20% !important;
  text-align: center !important;
  border-width: 0px !important;
}
.AllButtonsInLoginModal {
  width: 60% !important;
}
.LoginModalHeader {
  background-color: #00c389;
  border-radius: 0px;
}
.close {
  color: #fff !important;
}
.close:hover {
  color: #fff !important;
}
.LoginModalHeader h6 {
  color: #fff !important;
  font-family: "openSans-Bold" !important;
  text-align: center !important;
  text-transform: uppercase !important;
}
.LoginModalBody p {
  color: #91a8ae;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "openSans-Light";
}

.modal-content {
  border: none !important;
}
