@font-face {
  font-family: "bellaboo";
  src: url("./fonts/BELLABOO-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gotham-Bold";
  src: url("./fonts/Gotham%20Rounded%20Bold.otf");
  font-weight: 800;
  font-style: bold;
}

@font-face {
  font-family: "gotham-Meduim";
  src: url("./fonts/Gotham%20Rounded%20Medium.otf");
  font-weight: normal;
}

@font-face {
  font-family: "vag-Bold";
  src: url("./fonts/VAG.ttf");
  font-weight: 800;
  font-style: bold;
}

@font-face {
  font-family: "openSans-Bold";
  src: url("./fonts/OpenSans-Bold.ttf");
  font-weight: 800;
  font-style: bold;
}

@font-face {
  font-family: "openSans-Light";
  src: url("./fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "openSans-Semibold";
  src: url("./fonts/OpenSans-Semibold.ttf");
}

@font-face {
  font-family: "openSans";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Times-New-Romance";
  src: url("./fonts/Times New Romance.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto_Bold";
  src: url("./fonts/Roboto-Bold.ttf");
}

h1 {
  font-family: "gotham-Bold", sans-serif;
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
}

html {
  scroll-behavior: smooth;
}

.LoadingContainer {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10005;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cookiePopup_title {
  font-family: "vag-Bold";
  font-size: 18px;
  color: #fff;
}

.cookiePopup_paragraph {
  font-family: "openSans-Light";
  font-size: 12px;
  color: #fff;
}
