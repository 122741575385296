.Header {
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
  -moz-box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
  box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
}
.offert_logo {
  width: 100px;
  vertical-align: middle;
  float: left;
}

.connexion_btn,
.photos-empty .suggest_picture {
  background-color: rgba(255, 255, 255, 0);
  margin-right: 5px;
  border: 2px solid #00c389;
  color: #00c389 !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding: 12px 18px;
  box-shadow: none !important;
}
.inscription_btn:hover {
  background-color: #eda225;
  transition: all ease-in-out 200ms;
  color: white;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  box-shadow: none !important;
}
.inscription_btn {
  background-color: #f6ac32;
  transition: all ease-in-out 200ms;
  color: white;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding: 12px 27px;
  box-shadow: none !important;
}
.connexion_btn:hover,
.photos-empty .suggest_picture:hover {
  background-color: #01ae7b;
  transition: all ease-in-out 200ms;
  border: 2px solid #01ae7b;
  box-sizing: border-box;
  color: #fff !important;
  font-size: 14px !important;
  font-family: "gotham-Bold";
  box-shadow: none !important;
}
.nav a {
  text-decoration: none;
  color: #91a8ae;
  font-family: "openSans-Bold";
  transition: all ease-in-out 200ms;
  margin-left: 10px;
  margin-right: 10px;
}
.nav a:hover {
  text-decoration: none;
  color: #5d7a81;
  font-size: 17px;
  font-family: "openSans-Bold";
  transition: all ease-in-out 200ms;
}
.nav .selected {
  text-decoration: none;
  color: #00c389;
  font-size: 17px;
  font-family: "openSans-Bold";
}
.nav .selected:hover {
  color: #00c389;
  font-size: 17px;
}
.logo_link {
  width: 60%;
}
.monpharmacien-logo {
  border-right: 1px solid #dbe7f0;
  width: 100%;
  cursor: pointer;
}
.navbar-btn {
  /* min-width: 150px; */
}
.LogedInHeader {
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
  -moz-box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
  box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
}
.Header {
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
  -moz-box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
  box-shadow: 0px 3px 5px 0px rgba(24, 179, 161, 0.7);
}
.offert_logo {
  width: 100px;
  vertical-align: middle;
  float: left;
}
.offert_logo span {
  font-family: "Raleway-Regular", "openSans";
  color: #7b97aa;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 6px;
}
.monpharmacien-logo {
  border-right: 1px solid #dbe7f0;
}
.navbar-btn {
  min-width: 150px;
}
.avatarName {
  color: #91a8ae;
  font-size: 14px;
  font-family: "vag-Bold";
  margin-left: 8px;
}
.dropdown-toggle::after {
  display: none;
}
.avatarButton:focus {
  box-shadow: none !important;
}
.avatar {
  border-radius: 5px;
  border: 2px solid #1bc48a;
}
.AvaterDropDownMenu a {
  border-bottom: 1px solid #dbe7ed;
  color: #818c96;
  font-size: 14px;
  font-family: "openSans";
  padding: 6px 16px 6px 17px;
}
.AvaterLogout {
  border: none !important;
  color: #ff504d !important;
}
.dropdown_arrow {
  z-index: 25;
  position: absolute;
  top: -8px;
  left: 115px;
}
.AvaterDropDownMenu {
  left: -2vw !important;
}
.dropdown-item {
  cursor: pointer;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #00c389 !important;
  box-shadow: none !important;
  border: none !important;
}
.dropdown-menu {
  padding: 0.2rem 0 !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #818c96 !important;
  background-color: #f8f8f8 !important;
}
.AvaterLogout:focus,
.AvaterLogout:hover {
  background-color: #f8f8f8 !important;
  color: #ff504d !important;
}
.go_to_pro_link {
  color: #91a8ae;
  font-size: 14px !important;
  font-family: "openSans-Light" !important;
  cursor: pointer;
}
