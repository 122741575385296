.DeplomeModalInputs input {
  border-radius: 3px;
  border: 2px solid #e5eef7;
  background-color: #fff;
  color: #52747d;
  font-size: 13px;
  outline: none;
  padding-left: 5px;
}

.DeplomeModalInputs span {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
}
