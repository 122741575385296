.PharmacienCard {
  text-align: left !important;
}
.Pharmacie_infos {
  width: 100%;
}

.Pharmacie_infos li {
  display: flex;
  align-items: baseline;
}
.Pharmacie_infos ul {
  border-top: 1px solid #e7e7e7;
  padding-top: 5px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 5px;
}
.Pharmacie_infos p {
  font-family: "openSans-Bold" !important;
  font-size: 13px;
  color: #91a8ae;
  margin-bottom: 3px;
}
.Pharmacie_infos strong {
  font-family: "openSans-Bold" !important;
  font-size: 13px !important;
  color: #91a8ae !important;
  font-weight: bold !important;
  margin-left: 8px;
}
.Pharmacie_infos span {
  float: right;
  font-family: "openSans" !important;
  font-size: 13px;
  color: #91a8ae;
  padding-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 42%;
}
.bloc_pharmacie_title {
  font-family: "openSans-Bold" !important;
  font-size: 15px !important;
  color: #91a8ae !important;
  font-weight: bold !important;
  text-decoration: none;
}
.bloc_pharmacie_title:hover {
  text-decoration: none;
  color: #6e8d95 !important;
}
.CardButton {
  background-color: #00c389 !important;
  border-color: #00c389 !important;
  color: #fff !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: "gotham-Meduim" !important;
}
.CardButton:hover {
  background-color: #01ae7b !important;
  border-color: #01ae7b !important;
  color: #fff !important;
}
.IconStyle {
  width: 8%;
  font-size: 13px;
  color: #c7d8dc;
}

.card-body {
  padding: 0.75rem;
  padding-top: 0px !important;
}
.LastPharmaciesCards {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.PhotoAndtitleOfCard {
  cursor: pointer;
  height: 124px;
  overflow: hidden;
}
.PhotoAndtitleOfCard_text {
  height: 50px;
}
.card-img-top {
  object-fit: cover;
  height: 100%;
}
