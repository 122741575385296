.LastPharmacies {
  width: 100%;
  background-color: white;
  text-align: center;
}
.LastPharmaciesTitle {
  font-family: "vag-Bold" !important;
  font-size: 20px;
  color: #3d7ea8;
  text-transform: uppercase;
}
