.PhotoGalerieRempliComponent {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  cursor: pointer;
}
#PharmacyImage:hover + .PharmacyImageButton {
  opacity: 0.3;
}
#PharmacyImage:hover + .middle {
  opacity: 1;
}
.middle:hover {
  opacity: 1;
}
.PharmacyImageButton {
  background-color: #4caf50 !important;
  color: white !important;
  font-size: 12px;
  border: none !important;
  box-shadow: none !important;
}
.PharmacyImageButton:hover {
  background-color: #4caf50 !important;
  color: white !important;
  font-size: 12px !important;
  border: none !important;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
#PharmacyImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.PhotoGalerieRempliImage {
  height: 160px;
  width: 30%;
  margin-right: 2%;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  display: flex;
}
.emptyGalerie_paragraphe {
  font-family: "openSans";
  font-size: 13px;
  color: #91a8ae;
  margin-top: 3px;
  line-height: 19px;
}
.emptyGalerie_title {
  font-family: "openSans-Semibold";
  font-size: 20px;
  color: #a0b2b7;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
}
