.SecteurModalInputs select {
  border-radius: 3px;
  border: 2px solid #e5eef7;
  background-color: #fff;
  font-size: 13px;
  box-shadow: none !important;
}
.SecteurModalBody p {
  color: #91a8ae;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  font-family: "openSans-Light";
}
.SecteurModalInputs label {
  font-family: "openSans-Bold";
  font-size: 14px;
  color: #91a8ae;
  margin-bottom: 8px;
}
