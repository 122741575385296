.ModalLayoutHeader {
  background-color: #00c389;
  text-align: center;
}
.ModalLayoutHeader h6 {
  color: #fff !important;
  font-family: "openSans-Bold" !important;
  text-transform: uppercase !important;
}
.ModalLayoutBody p {
  color: #91a8ae;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  font-family: "openSans-Light";
}
.ModalLayoutButtons {
  display: flex;
  flex-direction: column;
}
.cancelBtn {
  background-color:  #fff;
  margin-right: 5px;
  border: 1px solid #b2eddb;
  color: #b2eddb !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  padding: 13px 23px 13px 23px !important;
  box-shadow: none !important;
}
.cancelBtn:hover {
  transition: all ease-in-out 200ms;
  border: 1px solid #00c389;
  box-sizing: border-box;
  color: #00c389 !important;
  font-size: 14px !important;
  font-family: "gotham-Bold" !important;
  box-shadow: none !important;
  padding: 13px 23px 13px 23px !important;
}
.sendBtn {
  background-color: #00c389 !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 13px 23px 13px 23px !important;
}
.sendBtn:hover {
  background-color: #01b47f !important;
  font-family: "gotham-Bold" !important;
  font-size: 14px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 13px 23px 13px 23px !important;
}

.modal-dialog {
  width: max-content !important;
}

.modal-header {
  /* padding: 0px 14px 0px 0px !important;
  height: 48px !important; */
  padding: 3px 16px;
}

/* .modal-header .close {
  padding: 17px 12px !important;
} */
